import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryPublicJobArgs } from '../../generated/types';

export const GET_PUBLIC_JOB = gql`
  query publicJob($userUuid: String!, $boardUuid: String!, $jobUuid: String!) {
    publicJob(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid) {
      uuid

      status
      feeling

      isThroughAgency
      agencyName
      agentName
      agentEmail
      agentPhone
      referralFee

      company
      companyInfo
      companyWebsite
      companyLinkedIn
      companyAvatar
      companyLocation
      companyLocationCity
      companyLocationCountry
      companyLocationLatitude
      companyLocationLongitude
      companyLocationPostCode
      companyLocationStreet
      companyLocationUrl
      companyDistances

      jobTitle
      jobSeniorityLevel
      jobDescription
      jobRequirement
      jobUrl

      ir35
      duration
      rate
      employmentType
      remoteOption

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
        temporaryImageUrl
      }

      events {
        uuid
        type
        startAt
        endAt
        isDeleted
        createdAt
        updatedAt
        meetingUrl
        description
        createdBy {
          uuid
          email
          nickname
          name
        }
        updatedBy {
          uuid
          email
          nickname
          name
        }
      }

      contacts {
        uuid
        fullName
        position
        email
        phoneNumber
        linkedin
      }

      notes {
        uuid
        title
        description
        isDeleted
        createdAt
        updatedAt
        createdBy {
          uuid
          email
          nickname
          name
        }
        updatedBy {
          uuid
          email
          nickname
          name
        }
      }

      tasks {
        uuid
        title
        description
        dueDate
        isFullDay
        priority
        isCompleted
        order
      }

      isOwner
      owner {
        uuid
        email
        nickname
        name
      }

      createdBy {
        uuid
        email
        nickname
        name
      }

      updatedBy {
        uuid
        email
        nickname
        name
      }

      createdAt
      updatedAt
      appliedAt
      interviewedAt
      offerAt
      rejectedAt
      acceptedAt

      followers {
        uuid
        email
        nickname
        name
        avatar
      }

      permissions
      isPublic
      publicUrl

      isNew
      isDeleted
    }
  }
`;

export const useGetPublicJob = (options?: QueryHookOptions<Pick<Query, 'publicJob'>, QueryPublicJobArgs>) =>
  useQuery<Pick<Query, 'publicJob'>, QueryPublicJobArgs>(GET_PUBLIC_JOB, options);

export const useLazyGetPublicJob = (options?: QueryHookOptions<Pick<Query, 'publicJob'>, QueryPublicJobArgs>) =>
  useLazyQuery<Pick<Query, 'publicJob'>, QueryPublicJobArgs>(GET_PUBLIC_JOB, options);
