import { useState } from 'react';

import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';
import { useActiveTab } from '../../../hooks/useActiveTab';

import { useGetBoard } from '../../../graph/queries/getBoard';
import { useGetJob, GET_JOB } from '../../../graph/queries/getJob';
import { useArchiveEvent } from '../../../graph/mutations/archiveEvent';
import { useUpdateEvent } from '../../../graph/mutations/updateEvent';
import { useCreateEvent } from '../../../graph/mutations/createEvent';
import { useCreateContact } from '../../../graph/mutations/createContact';
import { useInviteUserOnJob } from '../../../graph/mutations/inviteUserOnJob';
import { useUnfollowJob } from '../../../graph/mutations/unfollowJob';
import { useArchiveJob } from '../../../graph/mutations/archiveJob';
import { useCreateNote } from '../../../graph/mutations/createNote';
import { useArchiveNote } from '../../../graph/mutations/archiveNote';
import { useUpdateNote } from '../../../graph/mutations/updateNote';
import { useUpdateTask } from '../../../graph/mutations/updateTask';
import { useCreateTask } from '../../../graph/mutations/createTask';
import { useArchiveTask } from '../../../graph/mutations/archiveTask';
import { usePublishJob } from '../../../graph/mutations/publishJob';
import { useCompleteTask } from '../../../graph/mutations/completeTask';
import { GET_JOBS } from '../../../graph/queries/getJobs';

import {
  ViewWrapper,
  JobIntroWrapper,
  PillsWrapper,
  SharedLineWrapper,
  SectionTitle,
  SectionValue,
  SectionTitleInfo,
} from './View.style';
import { GenericErrorAlert, InfoAlert } from '../../../components/atoms/InfoAlert';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { PaperCollapse } from '../../../components/molecules/PaperCollapse';
import { Sections, Section } from '../../../components/atoms/Section';
import { PrimaryButton } from '../../../components/atoms/Button';
import { AddIcon, MailSendIcon, EditIcon, DeleteIcon, InfoIcon, AlertIcon } from '../../../components/atoms/Icons';
import { Pill } from '../../../components/atoms/Pill';
import { AddEventModal } from '../../../components/organisms/AddEventModal';
import { AddNoteModal } from '../../../components/organisms/AddNoteModal';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { AddContactModal } from '../../../components/organisms/AddContactModal';
import { InviteUserOnJobModal } from '../../../components/organisms/InviteUserOnJobModal';
import { ConfirmDeleteModal } from '../../../components/organisms/ConfirmDeleteModal/ConfirmDeleteModal';
import { Contact, ContactsList } from '../../../components/molecules/Contact';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { JobDescription } from '../../../components/organisms/JobDescription';
import { Event, EventsList } from '../../../components/molecules/Event';
import { ApplyJobModal } from '../../../components/organisms/ApplyJobModal';
import { useUpdateJob } from '../../../graph/mutations/updateJob';
import { InterviewJobModal } from '../../../components/organisms/InterviewJobModal';
import { OfferJobModal } from '../../../components/organisms/OfferJobModal';
import { CloseJobModal } from '../../../components/organisms/CloseJobModal';
import { AcceptJobModal } from '../../../components/organisms/AcceptJobModal';
import { JobPermissions } from '../../../components/molecules/Permissions/Permissions';
import { Copy } from '../../../components/atoms/Typography';
import { usePrepareCoverLetter } from '../../../graph/mutations/prepareAICoverLetter';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import { Paper } from '../../../components/atoms/Paper';
import { SharedLink } from '../../../components/molecules/SharedLink';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { Switch } from '../../../components/atoms/Switch';
import { Tooltip } from '../../../components/atoms/Tooltip';
import { IconButton } from '../../../components/atoms/Icons/Icon';
import { useGetPublicJob } from '../../../graph/queries/getPublicJob';
import { JobDescriptionReadOnly } from '../../../components/organisms/JobDescription/JobDescriptionReadOnly';

export const JobView = () => {
  const [activeTab, setActiveTab] = useActiveTab('job');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalVisibleType, setModalVisibleType] = useState<
    | ''
    | 'invite_job'
    | 'delete_job'
    | 'delete_event'
    | 'add_event'
    | 'edit_event'
    | 'edit_note'
    | 'add_note'
    | 'edit_note'
    | 'delete_note'
    | 'confirm_remove_follower'
    | 'add_contact'
    | 'apply_job'
    | 'interview_job'
    | 'offer_job'
    | 'accept_job'
    | 'close_job'
  >('');

  const { query: { userUuid = '', boardUuid = '', jobUuid = '' } = {} } = useRouter();
  // const {
  //   data: { board } = {},
  //   loading: loadingBoard,
  //   error: errorJob,
  // } = useGetBoard({
  //   variables: { userUuid, boardUuid },
  // });
  const {
    data: { publicJob } = {},
    loading,
    error,
  } = useGetPublicJob({
    variables: { userUuid, boardUuid, jobUuid },
    fetchPolicy: 'network-only',
  });

  let jobComponent = null;
  if (error) {
    jobComponent = (
      <InfoAlert>
        <Copy>Sorry!</Copy>
        <Copy styleLevel={2}>The job you are looking for does not exist or you do not have permission to view it.</Copy>
      </InfoAlert>
    );
  } else if (loading) {
    jobComponent = (
      <>
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
      </>
    );
  } else if (publicJob) {
    jobComponent = (
      <>
        <PaperCollapse title="Job Actions / Permissions"></PaperCollapse>

        {publicJob?.contacts && publicJob?.contacts?.length > 0 && (
          <ContactsList>
            {publicJob?.contacts?.map((contact) => {
              return <Contact key={contact.uuid} {...contact} />;
            })}
          </ContactsList>
        )}

        <ErrorBoundary message="Job View Description">
          <JobDescriptionReadOnly job={publicJob} activeTab={activeTab} setActiveTab={setActiveTab} />
        </ErrorBoundary>
      </>
    );
  } else {
    jobComponent = <GenericErrorAlert key="error" />;
  }

  return (
    <ViewWrapper>
      <Breadcrumbs>
        <Breadcrumb>Job: {publicJob?.jobTitle}</Breadcrumb>
      </Breadcrumbs>

      {jobComponent}
    </ViewWrapper>
  );
};
