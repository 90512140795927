import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { JobDescriptionProps } from './JobDescription.types';
import {
  CyclingIcon,
  DrivingIcon,
  ExternalLinkIcon,
  HomeIcon,
  LocationIcon,
  MailIcon,
  MoneyBillIcon,
  PeopleIcon,
  PhoneIcon,
  StarIcon,
  SuitcaseFullIcon,
  TransitIcon,
  WalkingIcon,
} from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Colors } from '../../../styles/colors';
import { HRSection } from '../../atoms/HRSection';
import { convertEnumToString } from '../../../utils/enum';
import { GoogleMap } from '../../molecules/GoogleMap/GoogleMap';
import { TabController, TabPanel } from '../../atoms/Tabs';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';
import { RichTextInputReadOnly } from '../../atoms/Form/RichTextInput/RichTextInput';
import { File as FileComponent } from '../../atoms/File';
import { JobStatus } from '../../../generated/types';
import { media } from '../../../styles/media';
import { Note } from '../../molecules/Note';
import { Section } from '../../atoms/Section';

export const JobDescriptionReadOnly: React.FC<any> = ({
  job,
  activeTab,
  setActiveTab,
}: React.PropsWithChildren<JobDescriptionProps>) => {
  const {
    isThroughAgency,
    agencyName,
    agentName,
    agentEmail,
    agentPhone,
    referralFee,
    company,
    companyWebsite,
    companyInfo,
    companyLocation,
    companyLocationCity,
    companyLocationCountry,
    companyLocationLatitude,
    companyLocationLongitude,
    companyLocationPostCode,
    companyLocationStreet,
    companyLocationUrl,
    companyDistances,
    jobTitle,
    jobDescription,
    jobRequirement,
    jobUrl,
    ir35,
    duration,
    rate,
    employmentType,
    remoteOption,
    createdAt,
    updatedAt,
    status,
    files,
    permissions,
  } = job;

  const IsNew = () => {
    const today = new Date();
    const isCreatedAt = new Date(createdAt) > new Date(today.setDate(today.getDate() - 7));
    const isUpdatedAt = new Date(updatedAt) > new Date(today.setDate(today.getDate() - 7));
    return isCreatedAt || isUpdatedAt;
  };

  const coordinates = {
    lat: companyLocationLatitude,
    lng: companyLocationLongitude,
  };

  return (
    <Container>
      {/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(job, null, 2)}</pre> */}

      <ErrorBoundary>
        <Intro>
          <IntroMain>
            <MainTitleWrapper>
              <CompanyName>
                {company}
                {Boolean(companyWebsite) && (
                  <Link target="_blank" to={companyWebsite}>
                    <ExternalLinkIcon />
                  </Link>
                )}
              </CompanyName>
              <PillsWrapper>
                {IsNew() && (
                  <Pill icon={<StarIcon />} isSmall={true} color={Colors.Contrast}>
                    New
                  </Pill>
                )}
                {ir35 && (
                  <Pill icon={<MoneyBillIcon />} isSmall={true} color={Colors.Primary}>
                    Within IR35
                  </Pill>
                )}
                {isThroughAgency && (
                  <Pill icon={<PeopleIcon />} isSmall={true} color={Colors.PrimaryDarkest}>
                    Agency
                  </Pill>
                )}
              </PillsWrapper>
            </MainTitleWrapper>
            <JobTitle>
              {jobTitle}
              {Boolean(jobUrl) && (
                <Link target="_blank" to={jobUrl}>
                  <ExternalLinkIcon />
                </Link>
              )}
            </JobTitle>
            <Salary>
              {Boolean(rate) && <span>{rate}</span>}
              {Boolean(duration && rate) && <span> / </span>}
              {Boolean(duration) && <span>{duration}</span>}
            </Salary>
            <Employment>
              {employmentType && (
                <EmploymentContent>
                  <SuitcaseFullIcon size={1.5} />
                  {convertEnumToString('EmploymentType', employmentType)}
                </EmploymentContent>
              )}
              {remoteOption && (
                <EmploymentContent>
                  <HomeIcon size={1.5} />
                  {convertEnumToString('RemoteOption', remoteOption)}
                </EmploymentContent>
              )}
            </Employment>
            {companyLocation && (
              <Location>
                <LocationIcon size={1.5} color={Colors.Contrast} />
                {companyLocation}
                {Boolean(companyLocationUrl) && (
                  <LocationUrl target="_blank" to={companyLocationUrl}>
                    <ExternalLinkIcon size={1} />
                  </LocationUrl>
                )}
              </Location>
            )}
          </IntroMain>
          <IntroRight>
            <Section>
              {isThroughAgency ? (
                <>
                  <SectionTitle>Agency</SectionTitle>
                  <SectionValue>{agencyName}</SectionValue>
                  <SectionValue>{agentName}</SectionValue>
                  {agentEmail && (
                    <SectionValue>
                      <MailIcon /> {agentEmail}
                    </SectionValue>
                  )}
                  {agentPhone && (
                    <SectionValue>
                      <PhoneIcon /> {agentPhone}
                    </SectionValue>
                  )}
                </>
              ) : (
                <SectionTitle>No Agency</SectionTitle>
              )}
            </Section>
          </IntroRight>
        </Intro>
      </ErrorBoundary>

      <HRSection />

      <ErrorBoundary>
        <TabController
          headers={[
            { id: 'job_description', label: 'Job Description' },
            { id: 'job_requirement', label: 'Job Requirement' },
            { id: 'company_info', label: 'Company Info' },
            { id: 'uploaded_files', label: 'Uploaded Files' },
          ]}
          initialActiveId={activeTab}
          onTabChanged={(tabId) => {
            setActiveTab(tabId);
          }}
        >
          <TabPanel $tabId="job_description">
            {jobDescription ? (
              <RichTextInputReadOnly>{jobDescription}</RichTextInputReadOnly>
            ) : (
              'Job Description not provided.'
            )}
          </TabPanel>
          <TabPanel $tabId="job_requirement">
            {jobRequirement ? (
              <RichTextInputReadOnly>{jobRequirement}</RichTextInputReadOnly>
            ) : (
              'Job Requirement not provided.'
            )}
          </TabPanel>
          <TabPanel $tabId="company_info">
            {companyInfo ? (
              <RichTextInputReadOnly>{companyInfo}</RichTextInputReadOnly>
            ) : (
              'Company Information not provided.'
            )}
          </TabPanel>
          <TabPanel $tabId="uploaded_files">
            {files && files.length > 0 ? (
              <FilesList>
                {files.map((file) => (
                  <FileComponent
                    key={file.uuid}
                    uuid={file.uuid}
                    status="uploaded"
                    mimeType={file.mimetype}
                    formattedMimetype={file.formattedMimetype}
                    filename={file.filename}
                    onDownloadClick={() => {
                      const win = window.open(file?.temporaryUrl || '', '_blank');
                      win?.focus();
                    }}
                  />
                ))}
              </FilesList>
            ) : (
              'No files uploaded.'
            )}
          </TabPanel>
        </TabController>
      </ErrorBoundary>

      {companyLocationLatitude && companyLocationLongitude && (
        <ErrorBoundary>
          <HRSection />
          <MapContainer>
            <MapContainerImage>
              <GoogleMap center={coordinates} />
            </MapContainerImage>
            <MapContainerDistance>
              {companyDistances?.driving?.distance && (
                <LineWrapper>
                  <DrivingIcon size={1.5} />
                  {companyDistances?.driving?.distance} / {companyDistances?.driving?.duration}
                </LineWrapper>
              )}
              {companyDistances?.walking?.distance && (
                <LineWrapper>
                  <WalkingIcon size={1.1} />
                  {companyDistances?.walking?.distance} / {companyDistances?.walking?.duration}
                </LineWrapper>
              )}
              {companyDistances?.bicycling?.distance && (
                <LineWrapper>
                  <CyclingIcon size={1.5} />
                  {companyDistances?.bicycling?.distance} / {companyDistances?.bicycling?.duration}
                </LineWrapper>
              )}
              {companyDistances?.transit?.distance && (
                <LineWrapper>
                  <TransitIcon size={1.5} />
                  {companyDistances?.transit?.distance} / {companyDistances?.transit?.duration}
                </LineWrapper>
              )}
            </MapContainerDistance>
          </MapContainer>
        </ErrorBoundary>
      )}
    </Container>
  );
};

const Container = styled.div``;

const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ApplicationStatus = styled.div`
  display: none;
  margin-bottom: 16px;
  border: 1px dotted ${Colors.Contrast};
  padding: 16px 0 8px 0;

  ${media.from_laptop`
    display: block;
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px 16px 8px 16px;
`;

const Action = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Intro = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MainTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  ${media.from_laptop`
    margin-bottom: 16px;
  `}
`;

const PillsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 8px;

  ${media.from_laptop`
    width: fit-content;
    gap: 16px;
  `}
`;

const CompanyName = styled.div`
  display: flex;
  font-size: 2rem;

  ${media.from_laptop`
    margin-bottom: 16px;
  `}
`;

const IntroMain = styled.div`
  flex: 4;
`;

const IntroRight = styled.div`
  flex: 1;
`;

const JobTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 8px;
`;

const Salary = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${Colors.Primary};
  margin-bottom: 8px;
`;

const Location = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1.1rem;
`;

const Employment = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

const EmploymentContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 8px;
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  color: ${Colors.GreyLightest};
  font-size: 1.2rem;
  line-height: 2rem;
`;

const SectionValue = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.GreyDarkest};
  font-size: 1.2rem;
  line-height: 2rem;
`;

const LocationUrl = styled(Link)`
  transform: translateY(-8px);
`;

const MapContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  height: 400px;
  width: 100%;
`;

const MapContainerImage = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  flex: 4;
`;

const MapContainerDistance = styled.div`
  /* border: 1px solid red; */
  padding: 16px;
  flex: 1;
`;

const getActiveStep = (status: JobStatus) => {
  switch (status) {
    case JobStatus.Wishlist:
      return 1;
    case JobStatus.Applied:
      return 2;
    case JobStatus.Interview:
      return 3;
    case JobStatus.Offer:
      return 4;
    case JobStatus.Accepted:
      return 5;
    case JobStatus.Closed:
      return 5;
    default:
      return 1;
  }
};
