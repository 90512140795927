import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';

export const ViewWrapper = ({ children }: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return <ViewWrapperInner>{children}</ViewWrapperInner>;
};

export const JobIntroWrapper = ({ children }: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return <JobIntroWrapperInner>{children}</JobIntroWrapperInner>;
};

const ViewWrapperInner = styled.div`
  /* border: 1px solid red; */
  padding-block-start: 16px;

  ${media.from_tablet`
    padding-block-start: 20px;
  `}

  > div {
    margin-bottom: 30px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;

const JobIntroWrapperInner = styled.div``;

export const PillsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
  gap: 16px;
`;

export const SharedLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  font-size: 1.3rem;
  font-weight: 600;
`;

export const SectionTitle = styled.div`
  color: ${Colors.GreyLightest};
  font-size: 1.2rem;
  line-height: 2rem;
  display: flex;
`;

export const SectionValue = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.GreyDarkest};
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 8px;
`;

export const SectionTitleInfo = styled.div`
  transform: translateY(-6px);
`;
